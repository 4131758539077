import React from "react";
import ExpertiseDomaine from "../../models/expertiseDomaine";

export default function Expertise({titre, description, image}: ExpertiseDomaine) {
    return (
        <>
            {/*<img src={image} alt="Expertises Ingénierie Logicielle" className="h-1/2 w-1/2"/>*/}
            <h1 className="text-3xl pb-4">{titre}</h1>
            <p className="text-left md:text-center w-full md:w-2/3">{description}</p>
        </>
    );
}