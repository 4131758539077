import React from "react";
import { Link } from "react-scroll";

export default function Item({ name, refId, handleOffCanvas } : {name: string, refId: string, handleOffCanvas?: any}) {

    return (
        <Link
            className="cursor-pointer text-xl"
            activeClass="active"
            to={refId}
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={handleOffCanvas}
        >
            {name}
        </Link>
    );
}