import React from "react";
import ServiceDomaine from "../../models/serviceDomaine";
import './service.css';

export default function Service({titre, description, code}: ServiceDomaine) {

    return (
        <div className="service p-5 bg-white-inprocess text-primary-inprocess m-2 rounded-lg shadow-inner hover:shadow-lg">
            <div className="pb-8">
                <i className={`fa-solid fa-${code} p-2 text-xl border rounded-lg text-secondary-inprocess shadow-inner`}></i>
            </div>
            <h1 className="text-sm font-bold pb-4">{titre}</h1>
            {/*<img src={image} alt="Expertises Ingénierie Logicielle" className="h-1/2 w-1/2 pb-4"/>*/}
            <p className="">{description}</p>
        </div>
    );
}