import React from "react";
import Formulaire from "./layouts/formulaire";
import Informations from "./layouts/informations";

export default function Devis() {
    return (
        <div className="py-5 px-5 md:py-10 md:px-8" id="contacts">
            <div className="text-2xl pt-4 pb-4">
                ENVIE DE NOUS ECRIRE !
                <hr className="text-secondary-inprocess h-px my-4 bg-secondary-inprocess border-0"/>
            </div>
            <div className="grid grid-rows-1 gap-1 mx-3 py-1 md:grid-cols-2 md:gap-2 md:mx-10 md:py-4">
                <Formulaire />
                <Informations />
            </div>
        </div>
    );
}