import React from "react";
import {Link} from "react-scroll";

export default function Footer() {
    return (
        <div className="cover-color flex flex-col text-light-inprocess py-5 px-5 md:py-10 md:px-8">
            <div className="pt-4 pb-4">
                <div className="flex flex-row justify-between">
                    <img src="logo.png" alt="Logo InProcess" className="w-1/2 md:w-1/6" />
                    <ul className="flex flex-row">
                        <li className="p-2 me-2"><a href="https://www.facebook.com/inprocess.sarl?mibextid=ZbWKwL" target="__blank" rel="noreferrer nofollow noopener"><i className="fa-brands fa-facebook text-2xl hover:text-green-inprocess"></i></a></li>
                        <li className="p-2 me-2"><a href="https://www.instagram.com/inprocess_sarl/?igshid=YTQwZjQ0NmI0OA%3D%3D" target="__blank" rel="noreferrer nofollow noopener"><i className="fa-brands fa-instagram text-2xl hover:text-green-inprocess"></i></a></li>
                        <li className="p-2 me-2"><a href="https://www.linkedin.com/company/inprocesssarl/" target="__blank" rel="noreferrer nofollow noopener"><i className="fa-brands fa-linkedin text-2xl hover:text-green-inprocess"></i></a></li>
                    </ul>
                </div>
                <hr className="text-secondary-inprocess h-px my-4 bg-secondary-inprocess border-0"/>
            </div>

            <div className="grid grid-rows-1 gap-2 md:grid-cols-4 h-full pt-4">
                <div className="col-span-1 md:col-span-2 flex flex-col">
                    <p className="text-justify">
                        INPROCESS SARL est une entreprise de droit sénégalais légalement constituée.
                        Forte de son expérience de plus de cinq ans dans les domaines de l&apos;ingénierie informatique,
                        de la communication globale et de la formation professionnelle, InProcess est une équipe
                        d&apos;experts qui trouve du plaisir à vous servir. Partout au Sénégal et dans la région ouest africaine,
                        InProcess se déploie au bonheur des ses partenaires.
                    </p>
                </div>
                <div className="col-span-1 flex flex-col ml-0 md:mx-auto">
                    <h1 className="pt-4 md:pt-0 pb-4">Nos Expertises</h1>
                    <ul>
                        <li className="p-2 hover:text-green-inprocess">
                            <Link className="cursor-pointer" activeClass="active" to="ingenierielogicielle" spy={true} smooth={true} offset={-50} duration={500}>
                                - Ingenierie Logicielle
                            </Link>
                        </li>
                        <li className="p-2 hover:text-green-inprocess">
                            <Link className="cursor-pointer" activeClass="active" to="cummunication" spy={true} smooth={true} offset={-50} duration={500}>
                                - Communication Globale
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-span-1 flex flex-col ml-0 md:mx-auto">
                    <h1 className="pt-4 md:pt-0 pb-4">Nos Horaires</h1>
                    <ul>
                        <li className="p-2 text-sm"><i className="fa-solid fa-clock me-2 light-inprocess"></i> Lundi-Vendredi de 08h00 - 17h00</li>
                        <li className="p-2 text-sm"><strong>NB :</strong> Toutes les visites se font sur rendez-vous.</li>
                    </ul>
                </div>
            </div>

            <div className="pt-4 pb-4 text-sm">
                <hr className="text-secondary-inprocess h-px my-4 bg-secondary-inprocess border-0"/>
                <div className="flex flex-col justify-between md:flex-row">
                    <p className="p-2 copy text-center md:text-left">© 2017 Inprocess SARL Tous drois reservés.</p>
                    {/*<ul className="flex flex-col mx-auto items-center md:mx-0 md:flex-row">
                        <li className="p-2 copy hover:text-green-inprocess"><a href="#">Condition d’utilisation</a></li>
                        <li className="p-2 copy hover:text-green-inprocess"><a href="#">Politique de confidentialité</a></li>
                        <li className="p-2 copy hover:text-green-inprocess"><a href="#">Vos choix en matière de confidentialité</a></li>
                    </ul>*/}
                </div>
            </div>
        </div>
    );
}