import React, {useEffect, useState} from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Partenaires() {
    const [nb, setNb] = useState(4);
    useEffect(() => {
        if(window.screen.width > 768) {
            setNb(4)
        } else {
            setNb(1)
        }
        window.addEventListener('resize', () => {
            if(window.screen.width > 768) {
                setNb(4)
            } else {
                setNb(1)
            }
        });
    }, [nb]);

    return (
        <div className="py-5 px-5 md:py-10 md:px-8">
            <div className="text-2xl pt-4 pb-4">
                DES ENTREPRISES ONT CHOISI INPROCESS
                <hr className="text-secondary-inprocess h-px my-4 bg-secondary-inprocess border-0"/>
            </div>
            <div className="mx-auto w-3/4 my-10">
                <OwlCarousel items={nb}
                             className="owl-theme"
                             autoplay={true}
                             autoplayTimeout={3000}
                             loop
                             margin={80} >
                    <div className="rounded-lg shadow-sm md:shadow-lg my-10"><img className="w-1/2 object-cover" src= {'expert.jpg'}/></div>
                    <div className="rounded-lg shadow-sm md:shadow-lg my-10"><img className="w-1/2 object-cover" src= {'adk.jpg'}/></div>
                    <div className="rounded-lg shadow-sm md:shadow-lg my-10"><img className="w-1/2 object-cover" src= {'ats.jpg'}/></div>
                    <div className="rounded-lg shadow-sm md:shadow-lg my-10"><img className="w-1/2 object-cover" src= {'labo.jpg'}/></div>
                    <div className="rounded-lg shadow-sm md:shadow-lg my-10"><img className="w-1/2 object-cover" src= {'phrase.jpg'}/></div>
                </OwlCarousel>
            </div>
        </div>
    );
}