import React, {useEffect, useState} from "react";
import "./accueil.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Logo from "../shared/logo";
import Item from "../shared/item";
import {Link} from "react-scroll";

export default function Accueil() {
    const [onCanvas, setOneCanvas] = useState(false);
    const [bg, setBg] = useState("bg-transparent");

    useEffect(() => {
        window.addEventListener('scroll', (e: Event)=> {
            if(window.scrollY > 10)
                setBg("bg-primary-inprocess");
            else setBg("bg-transparent");
        });
    }, [bg]);

    function handleClick() {
        setOneCanvas(!onCanvas);
    }

    return (
        <div className="cover-color h-100 md:h-full" id="/">
            {/*Menu Web*/}
            <div className={`${bg} fixed inset-x-0 top-0 z-20 mx-auto flex w-full items-center justify-between px-5 p-5 md:px-20`}>
                <Logo />
                <div className="hidden md:flex items-stretch space-x-6 text-light-inprocess">
                    <Item name="Accueil" refId="/"/>
                    <Item name="Expertises" refId="expertises"/>
                    <Item name="Méthodologie" refId="methodologie"/>
                    <Item name="Témoignages" refId="temoignages"/>
                    <Item name="Contacts" refId="contacts"/>
                </div>
                <a className="md:hidden" onClick={handleClick}><i className="fa-solid fa-bars text-light-inprocess text-lg"></i></a>
            </div>
            {/*Menu Web*/}
            <div className="grid grid-rows-1 md:grid-cols-2 gap-2 mx-auto items-center justify-center w-full md:w-3/4 h-100"
                 style={{backgroundImage: `url(circle.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: "right"}}
            >
                <div className="text-light-inprocess space-y-6 py-16 md:py-20 px-6">
                    <h1 className="text-2xl md:text-4xl">Nous accordons un soin particulier à chacune de vos idées</h1>
                    <p className="text-justify text-sm pe-4 py-4 rounded-lg backdrop-filter backdrop-blur-3xl bg-opacity-25">
                        Chez InProcess SARL, nous sommes spécialisés dans la fourniture de <strong>services en ingénierie logicielle</strong>,
                        en <strong>communication</strong> et en <strong>formation</strong> pour vous aider à faire évoluer votre entreprise vers de nouveaux sommets.
                        Partout au Sénégal et dans la région ouest-africaine, InProcess se déploie au bonheur de ses partenaires.
                    </p>
                    <p className="text-sm mb-5">
                        <strong>Ingénierie logicielle - Communication - Formation</strong>
                    </p>
                    <div>
                        <Link
                            className="rounded-lg border border-light-inprocess px-10 py-2 my-4 hover:bg-green-inprocess hover:border-secondary-inprocess cursor-pointer"
                            activeClass="active"
                            to="contacts"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                        >
                            Collaborons !
                        </Link>
                    </div>
                </div>
                <div className="hidden md:flex py-16 md:py-20 px-6">
                    {/*<img  className="" src= {'circle.svg'}/>*/}
                    <div className="container">
                        <OwlCarousel items={1}
                                     className="owl-theme"
                                     autoplay={true}
                                     autoplayTimeout={3000}
                                     loop
                                     dots={false}
                                     animateIn={true}
                        >
                            <div className="font-bold text-center text-6xl text-light-inprocess py-10">
                                <h1>Innovation</h1>
                            </div>
                            <div className="font-bold text-center text-6xl text-light-inprocess py-10">
                                <h1>Agilité</h1>
                            </div>
                            <div className="font-bold text-center text-6xl text-light-inprocess py-10">
                                <h1>Performance</h1>
                            </div>
                            <div className="font-bold text-center text-6xl text-light-inprocess py-10">
                                <h1>Qualité</h1>
                            </div>
                            <div className="font-bold text-center text-6xl text-light-inprocess py-10">
                                <h1>Créativité</h1>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            {/*Menu mobile*/}
            <>
                {
                    onCanvas &&
                    <div className="fixed top-0 p-5 bg-primary-inprocess w-full min-h-full z-30 md:w-2/5 backdrop-filter backdrop-blur-2xl bg-opacity-75">
                        <div className="flex flex-row justify-between">
                            <Logo/>
                            <div className="">
                                <a className="font-bold text-xl text-white-inprocess p-1 rounded-lg shadow-sm" onClick={handleClick}>
                                    <i className="fa-solid fa-xmark"></i>
                                </a>
                            </div>
                        </div>

                        <hr className="text-green-inprocess h-px my-6 bg-green-inprocess border-0"/>
                        <div className="flex flex-col mt-20 items-stretch space-y-6 text-center text-light-inprocess">
                            <Item name="Accueil" refId="/" handleOffCanvas={handleClick} />
                            <Item name="Expertises" refId="expertises" handleOffCanvas={handleClick} />
                            <Item name="Méthodologie" refId="methodologie" handleOffCanvas={handleClick} />
                            <Item name="Témoignages" refId="temoignages" handleOffCanvas={handleClick} />
                            <Item name="Contacts" refId="contacts" handleOffCanvas={handleClick} />
                        </div>
                    </div>
                }
            </>
            {/*Menu mobile*/}
        </div>
    );
}