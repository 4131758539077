import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
    lastname: string
    firstname: string
    email: string
    phone: string
    message: string
}

export default function Formulaire() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        console.log(data);
    }

    return (
        <div className="bg-white-inprocess shadow-md rounded p-5 mb-1 md:p-8 md:mb-4 h-full">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row justify-between">
                    <div className="mb-4 w-full me-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nom">
                            Nom
                        </label>
                        <input
                            className="shadow-inner appearance-none border rounded w-full py-2 px-3 text-sm
                            text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                            id="nom" type="text" placeholder="Votre nom"
                            {...register("lastname", { required: true })}
                            aria-invalid={errors.lastname ? "true" : "false"}
                        />
                        {errors.lastname?.type === "required" && (
                            <p className="text-sm font-serif text-red-400 mt-1">Nom est obligatoire.</p>
                        )}
                    </div>
                    <div className="mb-4 w-full">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="prenom">
                            Prénom
                        </label>
                        <input
                            className="shadow-inner appearance-none border rounded w-full py-2 px-3 text-sm
                            text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                            id="prenom" type="text" placeholder="Votre prénom"
                            {...register("firstname", { required: true })}
                            aria-invalid={errors.firstname ? "true" : "false"}
                        />

                        {errors.firstname?.type === "required" && (
                            <p className="text-sm font-serif text-red-400 mt-1">Prénom est obligatoire.</p>
                        )}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        E-mail
                    </label>
                    <input
                        className="shadow-inner appearance-none border rounded w-full py-2 px-3 text-sm
                        text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                        id="email" type="email" placeholder="Votre email"
                        {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })}
                        aria-invalid={errors.email ? "true" : "false"}
                    />
                    {errors.email?.type === "required" && (
                        <p className="text-sm font-serif text-red-400 mt-1">Email est obligatoire.</p>
                    )}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tel">
                        Téléphone
                    </label>
                    <input
                        className="shadow-inner appearance-none border rounded w-full py-2 px-3 text-sm
                        text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                        id="phone" type="tel" placeholder="Votre numéro de téléphone"
                        {...register("phone", { required: true })}
                        aria-invalid={errors.phone ? "true" : "false"}
                    />
                    {errors.phone?.type === "required" && (
                        <p className="text-sm font-serif text-red-400 mt-1">Numéro téléphone est obligatoire.</p>
                    )}
                </div>
                {/*<div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expertise">
                        Notre expertise demandé
                    </label>
                    <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                        id="expertise" placeholder="Votre email">
                        <option value="">Ingénierie Logicielle</option>
                        <option value="">Communication</option>
                        <option value="">Formation</option>
                    </select>
                </div>*/}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                        Message
                    </label>
                    <textarea className="shadow-inner appearance-none border rounded w-full py-2 px-3 text-sm
                    text-gray-700 leading-tight focus:outline-none focus:shadow focus:border-secondary-inprocess"
                              id="message" placeholder="Décrivez vos besoins en quelques mots (max 1000 caractèes)."
                              rows={5}
                              {...register("message", { required: true, minLength: 10 , maxLength: 1000 })}
                              aria-invalid={errors.message ? "true" : "false"}
                    />
                    {errors.message?.type === "required" && (
                        <p className="text-sm font-serif text-red-400 mt-1">Message est obligatoire.</p>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <button className="rounded-lg px-10 py-2 my-4 bg-green-inprocess border-secondary-inprocess w-full text-white-inprocess hover:bg-secondary-inprocess">
                        <i className="fa-solid fa-paper-plane me-3"></i>Envoyer</button>
                </div>
            </form>
        </div>
    );
}