import React from "react";
import Expertise from "./shared/expertise";
import Service from "./shared/service";

export default function Communication() {
    return (
        <div className="grid grid-rows-1 gap-0 p-1 md:p-3" id="cummunication">
            <div className="flex flex-col mx-auto md:items-center p-4">
                {/*<h1 className="text-secondary-inprocess pb-2 font-bold">Notre expertise en</h1>*/}
                <Expertise
                    titre="Communication"
                    description="Avec une approche globale, nous garantissons la cohérence de votre communication
                    pour renforcer votre image de marque et vous connecter avec votre cible."
                    image="com-event.svg"
                />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-4 gap-2">
                <Service
                    titre="Communication Corporate"
                    description="InProcess vous aide à développer une identité de marque solide et cohérente.
                    Nous élaborons des messages clés, des valeurs d'entreprise et des outils de communication pour
                    renforcer votre image de marque et communiquer efficacement avec vos parties prenantes internes
                    et externes."
                    code="handshake"
                />
                <Service
                    titre="Communication Digitale"
                    description="Nous concevons des stratégies de communication digitale pour vous aider à atteindre
                    votre public cible sur les plateformes en ligne. A travers les réseaux sociaux, les campagnes
                    d'e-mail marketing ou les sites web, nous utilisons des tactiques pour maximiser votre visibilité
                    et votre engagement."
                    code="bullhorn"
                />
                <Service
                    titre="Communication Evénementielle"
                    description="InProcess organise des évènements impactants pour promouvoir votre entreprise,
                    vos produits, vos services ou vos événements spéciaux. Nous prenons en charge la planification,
                    la promotion et la gestion globale de votre événement pour garantir son succès."
                    code="calendar-check"
                />
                <Service
                    titre="Conseil et Audit"
                    description="InProcess évalue l'efficacité de vos stratégies et processus de communication actuels.
                    Nous identifions les opportunités d'amélioration, proposons des recommandations personnalisées et
                    vous accompagnons dans la mise en œuvre de solutions pour optimiser vos efforts de communication."
                    code="list-check"
                />
            </div>
        </div>
    );
}