import React from "react";

export default function ItemTest({numero, title, description, image}: {numero: number, title: string, description: string, image: string}) {
    return (
        <div className="relative bg-white-inprocess rounded-lg p-10 text-primary-inprocess shadow-sm md:shadow-lg m-5" style={{backgroundImage: `url(${image})`, backgroundRepeat: 'no-repeat', backgroundSize: '80px', backgroundPositionX: "108%", backgroundPositionY: "108%"}}>
            <div className="flex flex-row absolute -top-0 -left-0">
                <h1 className="font-bold text-xl text-secondary-inprocess p-3 rounded-br-lg rounded-tl-lg shadow-sm border border-l-0 border-t-0">{numero}</h1>
            </div>
            <div className="flex flex-col">
                <h3 className="text-primary-inprocess text-xl">{title}</h3>
                <hr className="text-secondary-inprocess h-px mb-4 bg-secondary-inprocess border-0"/>
                <p className="text-justify">
                    {description}
                </p>
            </div>
        </div>
    );
}