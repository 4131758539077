import React from "react";
import Service from "./shared/service";
import Expertise from "./shared/expertise";

export default function IngenierieLogicielle() {
    return (
        <div className="grid grid-rows-1 gap-0 p-1 md:p-3" id="ingenierielogicielle">
            <div className="flex flex-col mx-auto md:items-center p-4">
                {/*<h1 className="text-secondary-inprocess pb-2 font-bold">Notre expertise en</h1>*/}
                <Expertise
                    titre="Ingenierie Logicielle"
                    description="InProcess conçoit et développe des solutions logicielles personnalisées qui répondent précisément à vos besoins."
                    image="dev.svg"
                />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-4 gap-2">
                <Service
                    titre="Développement d'Application"
                    description="Que vous ayez besoin d'une présence en ligne, d'une application mobile pour iOS et
                    Android, ou d'un logiciel de bureau pour Windows, macOS ou Linux, nous sommes là pour vous fournir
                    des solutions technologiques performantes et adaptées à vos besoins."
                    code="code"
                />
                <Service
                    titre="Design d’Application"
                    description="Nous accordons une attention particulière à l'expérience et à l'interface utilisateur
                    lors de la conception de nos applications. Notre équipe de designers talentueux crée des interfaces
                    attrayantes, intuitives et conviviales pour garantir une expérience utilisateur optimale."
                    code="layer-group"
                />
                <Service
                    titre="Infogérance & Support IT"
                    description="InProcess propose des services d'infogérance pour vous permettre de vous concentrer
                    sur votre cœur de métier. Nous assurons la maintenance et la gestion de votre infrastructure
                    informatique, garantissant ainsi la disponibilité, la sécurité et les performances de vos systèmes."
                    code="server"
                />
                <Service
                    titre="Conseil et Audit"
                    description="pour évaluer la qualité de votre code existant, pour obtenir des recommandations sur
                    l'architecture logicielle ou pour optimiser vos processus de développement, notre équipe d'experts
                    vous fournit des conseils personnalisés pour améliorer et maximiser les performances de votre système."
                    code="list-check"
                />
            </div>
        </div>
    );
}