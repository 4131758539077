import React from "react";
import Temoin from "../models/temoin";

export default function Temoignage({nom, profession, image, message}: Temoin) {
    return (
        <div className="relative flex flex-col bg-white-inprocess rounded-lg text-primary-inprocess my-4 p-5 md:p-8">
            {/*<div className="absolute flex items-center place-content-around -top-3 right-5 md:-top-6 md:right-10 bg-light-inprocess rounded-lg w-8 h-8 md:w-14 md:h-12 shadow ">
                <i className="fa-solid fa-quote-left text-secondary-inprocess text-lg md:text-3xl"></i>
            </div>*/}
            <div className="text-justify">
                {message}
                <hr className="text-green-inprocess h-px my-4 bg-green-inprocess border-0"/>
            </div>
            <div className="grid grid-cols-5 gap-4">
                <div className="col-span-1">
                    <img className="shadow-lg rounded-full" src={image}/>
                </div>
                <div className="col-span-4">
                    <h1 className="font-bold">{nom}</h1>
                    <h3 className="text-sm text-secondary-inprocess">{profession}</h3>
                </div>
            </div>
        </div>
    );
}