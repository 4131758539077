import React, {useEffect, useState} from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Temoignage from "./layouts/temoignage";

export default function Temoignages() {
    const [nb, setNb] = useState(2);

    useEffect(() => {
        if(window.screen.width > 768) {
            setNb(2)
        } else {
            setNb(1)
        }
        window.addEventListener('resize', () => {
            if(window.screen.width > 768) {
                setNb(2)
            } else {
                setNb(1)
            }
        });
    }, [nb]);

    return (
        <div className="bg-secondary-inprocess text-light-inprocess py-5 px-5 md:py-10 md:px-8" id="temoignages">
            <div className="text-2xl py-1 md:py-4">
                TEMOIGNAGES
                <hr className="text-green-inprocess h-px my-2 md:my-4 bg-green-inprocess border-0"/>
            </div>
            <div className="flex flex-col mx-auto w-full">
                <p className="text-xl text-center mb-4">Des clients satisfaits témoignent !</p>
                <div className="mx-auto w-full md:w-4/5 my-2 md:my-10">
                    <OwlCarousel items={nb}
                                 className="owl-theme"
                                 autoplay={true}
                                 autoplayTimeout={10000}
                                 loop
                                 margin={40}
                    >
                        <Temoignage
                            key={2}
                            nom="Ndeye Coumba Fall"
                            profession="Scrum master chez Sensoft"
                            image="part.png"
                            message="Des gens souriants, un accueil chaleureux, rigueur et efficacité !!! Voilà ce que
                            j'ai trouvé en franchissant la porte de InProcess SARL. Très agréablement surprise.
                            Je ne vous remercierai jamais assez pour tous vos services. 5/5"
                        />
                        <Temoignage
                            key={4}
                            nom="Ousmane AIDARA"
                            profession="DG ADK-AGRI"
                            image="aidara.jpg"
                            message="L'équipe de InProcess.Tech s'est montrée très professionnelle et à l'écoute de mes besoins.
                            Ils ont pris le temps de comprendre l'esprit de mon entreprise, mes valeurs et l'image que je voulais véhiculer."
                        />
                        <Temoignage
                            key={3}
                            nom="Mamadou D. DIALLO"
                            profession="Télépilote"
                            image="dioulde.jpg"
                            message="J'ai eu l'opportinuité de travailler en collaboration avec InProcess.Tech pour des missions
                            de télécommunication par drône. Leur expertise et leur professionnalisme dans le domaine m'ont impressionné dès le début."
                        />
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
}