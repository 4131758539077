import React from 'react';
import logo from './logo.svg';
import './App.css';
import Accueil from "./components/header/accueil/accueil";
import Expertises from "./components/expertises/expertises";
import Methodologie from "./components/methodologie/methodologie";
import Partenaires from "./components/partenaires/partenaires";
import Temoignages from "./components/temoignages/temoignages";
import Devis from "./components/devis/devis";
import Footer from "./components/footer/footer";
import Expertisesv2 from "./components/expertisesv2/expertisesv2";

function App() {
  return (
    <div>
        <Accueil />
        <Expertisesv2 />
        <Methodologie />
        <Partenaires />
        <Temoignages />
        <Devis />
        <Footer />
    </div>
  );
}

export default App;
