import React, {useEffect} from "react";
import IngenierieLogicielle from "./layouts/ingenierie-logicielle";
import Communication from "./layouts/communication";

export default function Expertisesv2() {
    return (
        <div className="py-4 px-5 md:px-8" id="expertises">
            <div className="text-2xl py-4">
                EXPLOREZ NOS DOMAINES D’EXPERTISE ET NOS SERVICES
                <hr className="text-secondary-inprocess h-px mt-4 bg-secondary-inprocess border-0"/>
            </div>

            <div className="flex flex-col mx-auto items-center space-y-10">
                <IngenierieLogicielle />
                <Communication />
            </div>
        </div>
    );
}