import React from "react";

export default function Informations() {
    return (
        <div className="flex flex-col px-2 md:px-10 h-full">
            <div className="h-1/2 md:h-1/3">
                <h1 className="text-2xl pb-4 pt-4 md:pt-0">Nos Contacts</h1>
                <ul className="text-lg">
                    <li className="pb-2 contact"><i className="fa-solid fa-phone me-4 text-secondary-inprocess"></i> +221 33 887 40 25 / +221 77 797 72 72</li>
                    <li className="pb-2 contact"><i className="fa-solid fa-envelope me-4 text-secondary-inprocess"></i> info@inprocess.tech</li>
                    <li className="pb-2 contact"><i className="fa-solid fa-location-dot me-4 text-secondary-inprocess"></i> En face CEA MITIC, UGB-Saint-Louis</li>
                    {/*<li className="pb-2 contact"><i className="fa-solid fa-clock text-secondary-inprocess me-4 text-secondary-inprocess"></i> Lundi-Vendredi de 08h00 - 17h00</li>*/}
                </ul>
            </div>
            <div className="h-1/2 md:h-2/3 mt-2">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.1977267048896!2d-16.427817924887684!3d16.055226084622255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe9557541972ba87%3A0xf38955c2c2014fa9!2sInProcess%20SARL!5e0!3m2!1sfr!2ssn!4v1693833018455!5m2!1sfr!2ssn"
                    className="border-0 w-full h-full" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}