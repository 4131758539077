import React, {useEffect, useState} from "react";
import ItemTest from "./layouts/itemTest";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Methodologie() {

    return (
        <div className="bg-light-inprocess py-5 px-5 md:py-10 md:px-8" id="methodologie">
            <div className="text-2xl pt-4 pb-4">
                NOTRE APPROCHE VERS LE SUCCES
                <hr className="text-secondary-inprocess h-px my-4 bg-secondary-inprocess border-0"/>
            </div>
            <div className="hidden md:flex flex-col mx-auto w-3/4 my-10">
                <p className="text-xl text-center mb-20">Avec notre méthodologie agile, nous réalisons <strong>pour</strong> et <strong>avec</strong> le client !</p>
                <img src="methodologie.svg" alt="Methodologie de travail chez InProcess SARL"/>
            </div>
            <div className="flex flex-col mx-auto my-3 md:hidden">
                <p className="text-xl text-center mb-5">Avec notre méthodologie agile, nous réalisons <strong>pour</strong> et <strong>avec</strong> le client !</p>
                <OwlCarousel items={1}
                             className="owl-theme"
                             loop
                             autoplay={true}
                             autoplayTimeout={5000}
                             margin={40}
                >

                    <ItemTest
                        numero={1}
                        title="Analyse de besoins"
                        description="A chaque fois et sans exception, nous accordons du temps pour bien
                    comprendre les détails des besoins des clients. Une analyse minutieuse pour s’assurer de bien
                    saisir l’idée ou le souhait des clients est une obligation."
                        image="met-1.svg"
                    />

                    <ItemTest
                        numero={2}
                        title="Prototypage"
                        description="Pour se faire une idée des rendus, nous procédons à la réalisation d’esquisses
                    pour tester, réajuster, valider, les choix et les orientations des projets."
                        image="met-2.svg"
                    />

                    <ItemTest
                        numero={3}
                        title="Conception"
                        description="Et c’est parti ! La conception ou le développement selon qu’il s’agit de
                    projet informatique ou de communication, cette phase correspond à la réalisation concrète
                    es propositions expertes faites aux clients."
                        image="met-3.svg"
                    />

                    <ItemTest
                        numero={4}
                        title="Suivi et Evaluation"
                        description="Rapidement mais sûrement ! Sous le regard de nos superviseurs et avec la
                    validation du client, la conception avance vite grâce à notre agilité et à nos années
                    d’expérience. Chaque étape est testée et validée à chaque phase de la conception"
                        image="met-4.svg"
                    />

                    <ItemTest
                        numero={5}
                        title="Déploiement/Livraison"
                        description="Avec des délais maitrisés et respectés, les commandes sont livrées avec tout
                    le soin nécessaire. InProcess s’assure à vous faire profiter pleinement de vos livrables
                    avec une assistance technique et divers conseils."
                        image="met-5.svg"
                    />

                    <ItemTest
                        numero={6}
                        title="Suivi et maintenance"
                        description="Nous livrons de bons produits et ce n'est pas tout. Chez InProcess nous
                    accompagnons nos clients dans l’utilisation et l’exploitation des produits. Une équipe
                    de suivi et de maintenance est constituée et mise à la disposition des clients."
                        image="met-6.svg"
                    />
                </OwlCarousel>
            </div>
        </div>
    );
}